import CallServiceTable from "../../components/Tables/CallServiceTable";
import { H3, Anchor } from "../../components/Text";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import TableComplete from "../../components/Tables/TableComplete";
import { Circle } from "../../components/Animation";
import RenderPdf from "../../containers/ManegeProcedure/RenderPdf";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import { useAuth } from "../../contexts/AuthContext";
import { Request } from "../../hooks/Request";
import ListDocument from "../../components/common/ListDocument";
import { headerList } from "../../utils/constant";
import { Button } from "../../components/Buttons";
import { ExportStatementAduana } from "../../containers/ManegeProcedure/ExportStatementAduana";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";
import { ImportClearancePdf } from "../../containers/ManegeProcedure/ImportClearancePdf";
import dayjs from "dayjs";
import config from "../../config.json";
import { PlanillaPDF } from "../../containers/clientList/PlanillaPDF";
import FacturaPDF from "../../containers/clientList/FacturaPDF";

const ClientList = () => {
  const { user } = useAuth();
  const { openDialog } = useDialog();
  const { openModal, onClose } = useModal();

  function listDocumentPdf(procedure) {
    openDialog(<RenderPdf procedure={procedure} />);
  }
  function generateSettlementProforma(procedure) {
    openDialog(<RenderLiquidaciónProforma procedure={procedure} />);
  }
  function viewProcedure(procedure) {
    openModal(
      <ProcedureData
        procedure={procedure}
        onClose={onClose}
        user={user}
        openDialog={openDialog}
      />
    );
  }

  function handleDocument(procedure) {
    openModal(<ListDocument procedure={procedure} deleteDocument={false} />);
  }

  function generateItems() {
    openDialog(
      <ExportStatementAduana
        companyId={user?.company[0]?.id}
        openDialog={openDialog}
      />
    );
  }

  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  function generateLiquidation(procedure) {
    openDialog(<ImportClearancePdf procedure={procedure} />);
  }

  if (user?.user) {
    return (
      <div>
        <CallServiceTable
          onClick={viewProcedure}
          component={({ reload }) => {
            return (
              <Button
                position="left"
                content="Generar reporte"
                onClick={() => generateItems(reload)}
              >
                <i className="fas fa-book"></i>
              </Button>
            );
          }}
          urlApi={`procedure/byuserid/${user?.user?.id}/companyId/${user?.company[0]?.id}/pag`}
          addFilters={[
            {
              name: "internCode",
              label: "Nro. interno",
              filter: true,
            },
            {
              name: "dimNumber",
              label: "Nro. DIM/DEX",
              filter: true,
            },
          ]}
          filters={[
            {
              name: "procedureType",
              url: "/procedureType",
              default: "Todos los tramites",
              valueOption: "id",
              labelOption: "name",
            },
            {
              name: "Year",
              default: "Todas las gestiones",
              options: [
                {
                  label: "2024",
                  name: "2024",
                  initial: true,
                },
                {
                  label: "2023",
                  name: "2023",
                },
                {
                  label: "2022",
                  name: "2022",
                },
                {
                  label: "2021",
                  name: "2021",
                },
                {
                  label: "2020",
                  name: "2020",
                },
                {
                  label: "2019",
                  name: "2019",
                },
                {
                  label: "2018",
                  name: "2018",
                },
                {
                  label: "2017",
                  name: "2017",
                },
                {
                  label: "2016",
                  name: "2016",
                },
                {
                  label: "2015",
                  name: "2015",
                },
              ],
            },
          ]}
          header={[
            ...headerList,
            {
              name: "Reports",
              type: "action",
              label: "Reportes",
              actions: [
                {
                  label: "Reporte de liquidación",
                  icon: "fas fa-print",
                  action: generateLiquidation,
                  color: "text-[#1d4ed8]",
                },
                {
                  label: "Generar hoja de ruta",
                  icon: "fas fa-route",
                  action: generateRoadmap,
                  color: "text-[#1d4ed8]",
                },
                // {
                //   label: "Ver documentos",
                //   icon: "fa-solid fa-folder",
                //   action: handleDocument,
                //   color: "text-[#1d4ed8]",
                // },
                // {
                //   label: "Generar informe de la recepción de documentos",
                //   icon: "fas fa-tasks",
                //   action: listDocumentPdf,
                //   color: "text-[#1d4ed8]",
                // },
                // {
                //   label: "Generar liquidación - proforma",
                //   icon: "fas fa-coins",
                //   action: generateSettlementProforma,
                //   color: "text-[#1d4ed8]",
                // },
              ],
            },
          ]}
          addUrl={`userId=${localStorage.getItem("id")}`}
          statusPaination={false}
          showTable={true}
        />
      </div>
    );
  } else {
    return <Circle />;
  }
};

const ProcedureData = ({ procedure, openDialog }) => {
  const { data, loading } = Request({
    urlApi: `/Procedure/numRef/${procedure.internCode}/Detail`,
  });

  const { data: dataDispatch, loading: loadingDispatch } = Request({
    method: "POST",
    urlApi: `${config.requestContaURL}/api/dispatchDocument`,
    dataRequest: {
      numRef: procedure.internCode,
    },
  });

  if (!loading || !loadingDispatch) {
    return <Circle />;
  }
  // function hadleDocument(list) {
  //   let listDocument = [];
  //   list.forEach((dataSet) => {
  //     if ((dataSet.type = "fileStatus")) {
  //       listDocument = [...listDocument, ...dataSet.fileStates];
  //     } else {
  //       listDocument = [
  //         ...listDocument,
  //         { ...dataSet, url: dataSet.value, name: dataSet.label },
  //       ];
  //     }
  //   });
  //   return listDocument;
  // }

  function handleFactura() {
    openDialog(<FacturaPDF dispatchDocument={dataDispatch} procedure={data} />);
  }

  function handlePlanilla() {
    openDialog(
      <PlanillaPDF dispatchDocument={dataDispatch} procedure={data} />
    );
  }

  const TextData = ({ title, children }) => {
    if (children) {
      return (
        <div className="flex gap-4 p-2 bg-white mb-2 rounded-md">
          <h3 className="font-semibold">{title}:</h3>
          <h4>{children}</h4>
        </div>
      );
    }
    return null;
  };
  return (
    <div>
      <div className="mb-2 rounded-md p-2">
        <h3 className="text-xl font-semibold text-center mb-3">Datos</h3>
        <TextData title="Carpeta">{data.numRef}</TextData>
        <TextData title="Referencia cliente">{data.referenciaCliente}</TextData>
        <TextData title="Mercadería">{data.mercaderia}</TextData>
        <TextData title="DIM">{data.dim}</TextData>
        <TextData title="Fecha de aceptación">{data.fechaAceptacion}</TextData>
        <TextData title="Fecha de inicio">
          {data.creationDate
            ? dayjs(data.creationDate).format("DD/MM/YYYY HH:mm")
            : "-"}
        </TextData>
        <TextData title="DIM adjuntada">
          {data?.dimUrl && data.dimUrl !== "-" ? (
            <a
              className="text-blue-600 underline"
              href={data.dimUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Enlace del documento
            </a>
          ) : (
            "-"
          )}
        </TextData>
        <TextData title="Planilla">
          <h3 className="text-blue-600 underline" onClick={handlePlanilla}>
            Enlace de la Planilla
          </h3>
        </TextData>
        <TextData title="Factura">
          <h3 className="text-blue-600 underline" onClick={handleFactura}>
            Enlace de la Factura
          </h3>
        </TextData>
      </div>
    </div>
  );
};

export default ClientList;
