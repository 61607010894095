import { Button } from "../../components/Buttons";
import { useModal } from "../../contexts/ModalContext";
import { H3 } from "../../components/Text";
import { useDialog } from "../../contexts/DialogContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { toast } from "react-toastify";
import { requestAuth } from "../../components/services/RequestService";
import { Request } from "../../hooks/Request";
import { useState } from "react";
import { validateArray } from "../../utils/validate";
import PdfTemplate from "../../containers/ManegeProcedure/PdfTemplate";
import { DialogConfirmation } from "../../components/Modal";
import { headerList } from "../../utils/constant";


const ManageArchive = () => {
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function editLot(lot, reload) {
    // if(lot.status==="Entregado"){
    //   toast.warning("No tiene los permisos para ver el lote");
    //   return;
    // }
    openModal(<FormEditLot lot={lot} reload={reload} onClose={onClose} />);
  }

  function changeStateRecibido(lot, reload) {

    const formData = {
      status: "Recibido",
      num: lot.num,
      id: lot.id,
      userId: lot.userId,
    };
    
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quiere recibir el lote?</H3>}
        onClose={dialogClose}
        method="put"
        data={ formData }
        url={`/lot`}
        texBtn="Confirmar"
        handleSuccess={reload}
      />
    );
    
  }

  function changeStateConcluir(lot, reload) {


    const formData = {
      status: "Concluido",
      num: lot.num,
      id: lot.id,
      userId: lot.userId,
    };
    
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quiere concluir el lote?</H3>}
        onClose={dialogClose}
        method="put"
        data={ formData }
        url={`/lot`}
        texBtn="Confirmar"
        handleSuccess={reload}
      />
    );
    
  }

  function createLot(reload) {
    openModal(<FormLot reload={reload} onClose={onClose} />);
  }


  return (
    <>
      <CallServiceTable
        urlApi="/lot/pag"
        component={({ reload }) => {
          return (
            <Button className="py-2" onClick={() => createLot(reload)}>
              Retornar Observados <i className="fa-solid fa-rotate-left"></i>
              {/* <i className="fa-solid fa-plus"></i> */}
              
            </Button>
          );
        }}
        addUrl="order=Entregado-Recibido-Observado-Concluido-ObsArchivo-Creado"
        filters={[
          {
            name: "Status",
            label: "Todos los estados",
            default: "Todos los estados",
            options: [
              {
                label: "Creado",
                name: "Creado",
              },
              {
                label: "Entregado",
                name: "Entregado",
              },
              {
                label: "Recibido",
                name: "Recibido",
              },
              {
                label: "Observado",
                name: "Observado",
              },
              {
                label: "Concluido",
                name: "Concluido",
              },
            ],
          },
        ]}
        header={[
          {
            label: "Nro de lote",
            name: "num",
            filter: true,
          },
          {
            label: "Fecha de Creación",
            name: "creationDate",
            type: "date",
          },
          {
            label: "Estado",
            name: "status",
            type: "custom",
            builder: (status) => {

              const baground = status==='Entregado' 
                              ? 'bg-green-600' 
                              : status==='Observado'
                              ? 'bg-orange-600'
                              : status==='Concluido'
                              ? 'bg-gray-500'
                              : status==='Recibido'
                              ? 'bg-yellow-500'
                              : status==='ObsArchivo'
                              ? 'bg-red-600'
                              : 'bg-blue-800'

              const state = status==='Entregado' 
                              ? 'Entregado' 
                              : status==='Observado'
                              ? 'Observado'
                              : status==='Concluido'
                              ? 'Concluido'
                              : status==='Recibido'
                              ? 'Recibido'
                              : status==='ObsArchivo'
                              ? 'Retornado'
                              : 'Creado'

              // const baground = status==='Creado'
              //                 ? 'bg-blue-800' 
              //                 : status==='ObsArchivo'
              //                 ? 'bg-red-600'
              //                 : 'bg-green-600'

              // const state = status==='Creado'
              //                 ? 'Creado' 
              //                 : status==='ObsArchivo'
              //                 ? 'Observado'
              //                 : 'Entregado'
              
              return (
                  <span className={`block w-fit px-2 py-1 rounded-full text-white ${ baground }`}>
                    { state }
                  </span>
              );
            },
          },
          {
            label: "Nombre del Usuario",
            name: "nameUser",
          },
          {
            label: "Apellido del Usuario",
            name: "fatherLastNameUser",
          },
          {
            name: "lotProcedures",
            label: "Nro. Interno",
            type: "custom",
            builder: (data) => {
              return (
                <div className="">
                  {data && validateArray(data)
                    ? data.map((item) => (
                        <div key={item.id} className="flex flex-wrap">
                          <p className="border-2 border-gray-500 rounded-md px-1">
                            {item.nroInterno || "---"}
                          </p>
                        </div>
                      ))
                    : null}
                </div>
              );
            },
          },
          {
            name: "id",
            label: "Recibir",
            type: "custom",
            builder: (_, __, lot, call) => {
              return (
                <button 
                  className={`block p-2 text-white rounded-lg ${lot.status==="Entregado"? "bg-yellow-500 hover:bg-yellow-600" : "cursor-default bg-gray-300"}`}
                  onClick={()=>changeStateRecibido(lot, call)}
                  disabled={ lot.status!=="Entregado" }
                >{ 
                  lot.status==="Entregado" 
                  ? "Recibir" 
                  : lot.status==="Creado" 
                  ? "Recibir" 
                  : "Recibido" 
                }</button>
              );
            },
          },
          {
            name: "id",
            label: "Concluir",
            type: "custom",
            builder: (_, __, lot, call) => {
              return (
                <button 
                  className={`block p-2 text-white rounded-lg ${lot.status==="Recibido"? "bg-gray-500 hover:bg-gray-600" : "cursor-default bg-gray-300"}`}
                  onClick={()=>changeStateConcluir(lot, call)}
                  disabled={ lot.status!=="Recibido" }
                >{ 
                  lot.status==="Recibido" 
                  ? "Concluir" 
                  : lot.status!=="Recibido"
                  ? "Concluir"
                  : "Concluido" 
                }</button>
              );
            },
          },
          {
            name: "edit",
            type: "action",
            label: "Acciones",
            actions: [
              {
                label: "Revisar Carpetas",
                icon: "fas fa-eye",
                action: editLot,
                color: "text-[#585858]",
              },
              
            ],
            
          },
          
        ]}
      />
    </>
  );
};

const FormLot = (props) => {
  const [procedureSelected, setProcedureSelected] = useState([]);
  const { openDialog } = useDialog();

  const { onClose, reload } = props;

  function handleDeleteItem(id) {
    setProcedureSelected(
      procedureSelected.filter((procedure) => procedure.id !== id)
    );
  }

  async function addLots() {

    const formData = {
      // procedureIds: procedureSelected.map((p) => p.procedureId),
      procedureIds: [],
      status: "ObsArchivo"
    };

    let lotId=null
    // let lotNum=null

    await requestAuth("post", "/lot", formData)
      .then((res) => {
        onClose();
        reload();
        // toast.success("Se creo con éxito");
        lotId = res.data.id
        // lotNum = res.data.num
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });

      for(let i=0; i<procedureSelected.length; i++){

        let formData = {
          obs: procedureSelected[i].obs,
          obs2: null,
          status: "Creado",
          // num: lotNum,
          num: procedureSelected[i].num,
          lotId: lotId,
          procedureId: procedureSelected[i].procedureId
        };
    
        await requestAuth("post", "/lot/loteProcedure", formData)
          .then((res) => {
            onClose();
            reload();
            // toast.success("Se Añadio con éxito");
          })
          .catch(() => {
            toast.error("Se produjo un error al subir el archivo");
          });

        await requestAuth("delete", `/Lot/loteProcedure/${procedureSelected[i].id}`, {})
          .then((res) => {
            // setload(false)
            toast.success('Se guardaron los cambios')
            onClose()
            reload();
            // handleSuccess(res.data)
          })
          .catch((err) => {
            // setload(false)
            toast.error('No se pudo guardar')
          })
      }



  }

  function openLote() {
    openDialog(
      <SelectProcedureDialog
        procedureSelected={procedureSelected}
        setProcedureSelected={setProcedureSelected}
      />
    );
  }


  return (
    <>
      <div className="flex gap-5 justify-between text-center mt-2">
        <h2 className="text-2xl font-bold">Lista de Archivos</h2>
        <Button className="py-2" onClick={() => openLote()}>
          Seleccionar Archivos <i className="fa-solid fa-plus"></i>
        </Button>
      </div>
      <div className="m-5 ">
        {validateArray(procedureSelected) ? (
          procedureSelected.map((procedure) => (
            <div
              key={procedure.id}
              className="bg-white rounded-md px-5 py-2 mb-2 flex gap-2 justify-between"
            >
              <div>
                <p>{procedure.nroInterno}</p>
                <p>{procedure.companyRazonSocial}</p>
              </div>
              <button onClick={() => handleDeleteItem(procedure.id)}>
                <i className="fas fa-trash text-[#ff2d55]"></i>
              </button>
            </div>
          ))
        ) : (
          <p className="text-center font-semibold text-red-400">
            No se selecciono ninguna carpeta
          </p>
        )}
      </div>
      <Button className="py-2 w-full" disabled={ procedureSelected.length === 0 } onClick={() => addLots()}>
        Enviar Lote <i className="fa-solid fa-paper-plane"></i>
      </Button>
    </>
  );
};

const FormEditLot = (props) => {
  const { openDialog, dialogClose } = useDialog();

  const { lot, onClose, reload } = props;

  const { data, call } = Request({
    urlApi: `/lot/${lot.id}/lotProcedures`,
  });

  const [state, setState] = useState(lot.status)

  if(state==="verificar"){
    changeStateRecibido();
  }

  function addObservation(procedure) {
    openDialog(
      <InputObservation lot={lot} dialogClose={ dialogClose } procedure={ procedure } reload={ reload } call={call} state={state} setState={setState} />
    );
  }

  

  async function changeStateRecibido() {

    if(data?.some(item => item.status === 'Observado')) return;

    const formData = {
      status: "Recibido",
      num: lot.num,
      id: lot.id,
      userId: lot.userId,
    };
    
    await requestAuth("put", `/lot`, formData)
      .then((res) => {
        reload();
        // toast.success("Lote recibido");
      })
      .catch(() => {
        toast.error("Error al cambiar estado");
      });
    
  }


  function handleTemplatePdf(procedure, lot) {
    openDialog(<PdfTemplate procedure={procedure} lot={lot} />);
  }


  return (
    <>
      <div className="flex gap-5 justify-center">
        <h2 className="text-2xl font-bold">Lista de Lotes</h2>
      </div>
      <div className="my-10 mx-5">
        {data?.map((procedure) => (
          
                // procedure.status === "Observado" 
                procedure.obs !== null 
                ? <div key={procedure.id} className="bg-white rounded-md px-5 py-2 mb-2">
                    <div                  
                      className="flex gap-2 justify-between items-center"
                      >
                      <div>
                        <p>{procedure.nroInterno}</p>
                        <p>{procedure.companyRazonSocial || "---"}</p>
                      </div>
                      
                      <div>
                        <p className="text-red-500 text-center font-bold">Observado</p>
                        <div className="flex gap-1">
                          {
                            lot.status!=="ObsArchivo" 
                            ? <button onClick={() => addObservation(procedure)} className="block bg-[#F3B303] hover:bg-[#ECC759] text-white text-sm rounded-full px-2 py-1">
                                <i className="fas fa-pencil text-white mr-2"></i>
                                Editar
                              </button>
                            : ""
                          }
                          {/* <button onClick={() => addObservation(procedure)} className="block bg-[#F3B303] hover:bg-[#ECC759] text-white text-sm rounded-full px-2 py-1">
                            <i className="fas fa-pencil text-white mr-2"></i>
                            Editar
                          </button> */}
                          <button onClick={() => handleTemplatePdf(procedure, lot)} className="block bg-[#FF8257] hover:bg-[#FF8257] text-white text-sm rounded-full px-2 py-1">
                            <i className="fas fa-file-pdf text-white mr-2"></i>
                            Imprimir
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="p-5 bg-red-300 border border-red-600 rounded-lg mt-5">
                      <p>{procedure.obs}</p>
                    </div>
                  
                  </div>
                : <div
                    key={procedure.id}
                    className="bg-white rounded-md px-5 py-2 mb-2 flex gap-2 justify-between items-center"
                  >
                    <div>
                      <p>{procedure.nroInterno}</p>
                      <p>{procedure.companyRazonSocial || "---"}</p>
                    </div>
                    <div>
                        <p className="text-green-500 text-center font-bold">Sin observaciones</p>
                        {
                          lot.status==="Concluido" || lot.status==="Creado" || lot.status==="Entregado" || lot.status==="ObsArchivo"
                          ? ""
                          : <button onClick={() => addObservation(procedure)} className={`block bg-green-700 text-white text-sm rounded-full px-2 py-1 hover:bg-[#629947]`}>
                              <i className="fas fa-pen text-white mr-2"></i>
                              Añadir obsevación
                            </button>
                        }
                        {/* <button onClick={() => addObservation(procedure)} disabled={lot.status==="Concluido" || lot.status==="Creado"} className={`block bg-green-700 text-white text-sm rounded-full px-2 py-1 ${lot.status==="Concluido" || lot.status==="Creado" ? "cursor-not-allowed" : "hover:bg-[#629947]"}`}>
                          <i className="fas fa-pen text-white mr-2"></i>
                          Añadir obsevación
                        </button> */}
                        
                      </div>
                  </div>
            
          
        ))}
      </div>

      <Button className="py-2 block mx-auto" onClick={() => onClose()}>
        Cerrar
      </Button>
    </>
  );
};

const InputObservation = ({ dialogClose, procedure, reload, call, state, setState, lot }) => {

  const [inputValue, setInputValue] = useState(procedure.obs || "");

  const inputChange = ( {target} ) => {
    setInputValue(target.value);
  }


  async function addObservation() {

    let formData = {
      id: procedure.id,
      obs: inputValue.trim().length===0 ? null : inputValue.trim(),
      obs2: procedure.obs2?.trim().length===0 ? null : procedure.obs2,
      status: "Observado",
      num: procedure.num,
      lotId: procedure.lotId,
      procedureId: procedure.procedureId
    };
    let msg="Se guardo la observación";
    let stateInt="Observado";

    if(inputValue.trim().length === 0) {
      msg="Se quito la observación"
      formData.status="Creado"
      stateInt="verificar";
    }
    

    await requestAuth("put", `/lot/loteProcedure`, formData)
      .then((res) => {
        dialogClose();
        reload();
        toast.success(msg);
        call();       
        if(stateInt==="Observado"){
          changeState(stateInt);
        }
        setState(stateInt)
      })
      .catch(() => {
        toast.error("Se produjo un error al añadir observación");
      });

    
  }


  async function changeState(statePro) {

    if(state==="Observado") return;

    const formData = {
      status: statePro,
      num: lot.num,
      id: procedure.lotId,
      userId: localStorage.getItem("id"),
    };
    
    await requestAuth("put", `/lot`, formData)
      .then((res) => {
        // dialogClose();
        reload();
        // toast.success("Se cambio el estado del lote con éxito");
      })
      .catch(() => {
        toast.error("Se produjo un error al actualizar el estado del lote");
      });
    
  }

  return (
    <>
      <h4 className="text-center text-xl font-bold">Observaciones</h4>
      <input 
        type="text"
        className="w-full bg-white border border-gray-300 px-3 py-2 rounded-md outline-none caret-blue-600 focus:border-blue-600 my-3" 
        placeholder="Sin observaciones"
        value={ inputValue }
        onChange={ inputChange }
      />
      <div className="w-96 flex gap-4 m-4">        
          <>
            <Button
            onClick={() => dialogClose()}
              className="w-full bg-[#fff] text-[#E7641D] border-2 border-[#E7641D]"
            >
              Cancelar
            </Button>
            <Button 
              onClick={() => addObservation()}
              className="w-full bg-[#E7641D]">
              Guardar
            </Button>
          </>       
      </div>
    </>
  )
}

const SelectProcedureDialog = ({ procedureSelected, setProcedureSelected }) => {

  const [proceduresList, setProceduresList] = useState(procedureSelected)

  function handleAdd(procedure, reload) {
    if (proceduresList.find((p) => p.id === procedure.id)){
      toast.warning("El archivo ya existe en el lote");
      return;
    }
    setProceduresList([...proceduresList, procedure])
    setProcedureSelected([...proceduresList, procedure]);
  }

  return (
    <div className="w-[70vw]">
      <H3 className="mb-2">
        Carpetas seleccionadas:{" "}
        {proceduresList.map((item) => (
          <span key={item.id} className="bg-red-300 mx-1 px-2 rounded-lg">
            {item.internCode || item.nroInterno}
          </span>
        ))}
      </H3>
      <CallServiceTable
        urlApi="/lot/procedures/pag"
        addUrl="status=Observado"
        
        // filters={[
        //   {
        //     name: "Status",
        //     label: "Todos los estados",
        //     default: "Todos los estados",
        //     options: [
        //       {
        //         label: "Creado",
        //         name: "Creado",
        //       },
        //       {
        //         label: "Entregado",
        //         name: "Entregado",
        //       },
        //       {
        //         label: "Recibido",
        //         name: "Recibido",
        //       },
        //       {
        //         label: "Observado",
        //         name: "Observado",
        //       },
        //       {
        //         label: "Concluido",
        //         name: "Concluido",
        //       },
        //     ],
        //   },
        // ]}
        header={[
          {
            label: "Nro. Hoja de Control",
            name: "num",
          },
          {
            label: "Nro de Interno",
            name: "nroInterno",
            filter: true,
          },
          {
            label: "Nro de Lote",
            name: "lotNum",
            filter: true,
          },
          {
            label: "Fecha de Creación",
            name: "creationDate",
            type: "date",
          },
          {
            label: "Estado",
            name: "status",
            type: "custom",
            builder: (status) => {
              
              return (
                  <span className={`block w-fit px-2 py-1 rounded-full text-red-500 border border-red-500`}>
                    { status }
                  </span>
              );
            },
          },
          {
            label: "Cliente",
            name: "companyRazonSocial",
          },
          {
            name: "manage",
            type: "action",
            label: "Gestionar",
            sticky: true,
            actions: [
              {
                label: "Agregar",
                icon: "fas fa-plus",
                action: handleAdd,
                color: "text-[#646464]",
              },
            ],
          },
          
        ]}
      />
      {/* <CallServiceTable
        // filters={[
        //   {
        //     name: "procedureType",
        //     url: "/procedureType",
        //     default: "Todos los tramites",
        //     valueOption: "id",
        //     labelOption: "name",
        //   },
        //   {
        //     name: "companyId",
        //     url: "/company/getallcompanies",
        //     default: "Todas las compañías",
        //     valueOption: "id",
        //     labelOption: "razonSocial",
        //   },
        //   {
        //     name: "Year",
        //     default: "Todas las gestiones",
        //     options: [
        //       {
        //         label: "2024",
        //         name: "2024",
        //         initial: true,
        //       },
        //       {
        //         label: "2023",
        //         name: "2023",
        //       },
        //       {
        //         label: "2022",
        //         name: "2022",
        //       },
        //       {
        //         label: "2021",
        //         name: "2021",
        //       },
        //       {
        //         label: "2020",
        //         name: "2020",
        //       },
        //       {
        //         label: "2019",
        //         name: "2019",
        //       },
        //       {
        //         label: "2018",
        //         name: "2018",
        //       },
        //       {
        //         label: "2017",
        //         name: "2017",
        //       },
        //       {
        //         label: "2016",
        //         name: "2016",
        //       },
        //       {
        //         label: "2015",
        //         name: "2015",
        //       },
        //     ],
        //   },
        // ]}
        urlApi="/Lot/procedures/pag"
        // addFilters={[
        //   {
        //     name: "internCode",
        //     label: "Nro. interno",
        //     filter: true,
        //   },
        //   {
        //     name: "dimNumber",
        //     label: "Nro. DIM/DEX",
        //     filter: true,
        //   },
        //   {
        //     name: "númeroDePedido",
        //     label: "Nro. de Pedido",
        //     filter: true,
        //   },
        // ]}
        header={[
          ...headerList,

          {
            name: "manage",
            type: "action",
            label: "Gestionar",
            sticky: true,
            actions: [
              {
                label: "Agregar",
                icon: "fas fa-plus",
                action: handleAdd,
                color: "text-[#646464]",
              },
            ],
          },
        ]}
      /> */}
    </div>
  );
};


export default ManageArchive;
